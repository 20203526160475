import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from "../components/layout"
import SEO from "../components/seo"
import TipTeaser from '../components/TipTeaser'
import styled from 'styled-components'
import bgimage from '../images/banner-images/tips.jpg'
import CategoryNav from '../components/categoryNav'
import Pagination from '../components/Pagination'


class TipIndex extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulTip.edges');
    const categories = get(this, 'props.data.allContentfulCategory.edges');
    const { currentPage, numPages } = this.props.pageContext;

    return (
      <Layout>
      <SEO title="Tips - View all our available hints and tips" image={bgimage} />

       <Banner>
          <Container>
            <h1>All our tips</h1>
          </Container>
        </Banner>

        <Main>
          <Description>
            <p>Browse through all of our available tips, have a tip of your own? Why not <a href="/submit-tip">submit your tip</a>?</p>
          </Description>

             <CategoryNav data={categories}/>

        </Main>


         <TeaserList>
              {posts.map(({ node }) => {
                return (
                  <TeaserListItem key={node.slug}>
                    <TipTeaser tip={node} />
                  </TeaserListItem>
                )
              })}
        </TeaserList>
          
          <Pagination pages={numPages} current={currentPage} slug={`/tips`} />

      </Layout>
    )
  }
}

export default TipIndex


export const pageQuery = graphql`
  query TipIndexQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulTip(sort: {fields: createdAt, order: DESC}, limit: $limit, skip: $skip) {
      edges {
        node {
          title
          slug
          timeToRead 
          category {
             name
           }
          image {
            file {
              url
            }
            fluid(maxWidth: 1400, maxHeight: 900, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
    
    allContentfulCategory {
        edges {
          node {
            name
            slug
          }
        }
     }
  }
`;

const TeaserList = styled.ul`
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media(max-width: 500px) {
    flex-direction: column;
  }
`;
const TeaserListItem = styled.li`
  list-style: none;
  flex: 1 0 30%; 
  margin: 10px; 
`;

const Banner = styled.section`
  background-image: url(${bgimage});
  height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  margin-bottom: 40px;
`;

const Container = styled.div`
  max-width: 800px;
   margin: auto; 
   display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  padding-bottom: 40px;

  h1 {
    font-size: 30px;
  }

  @media(max-width: 768px) {
    padding-left: 20px;
  }
`;

const Main = styled.div`
  max-width: 900px;
  margin: 60px auto; 
  color: #fff;

  @media(max-width: 768px) {
    padding: 0 20px;
    margin-top: 0;
  }

  a {
    color: #3c7db6;
    text-decoration: none;
  }
`;

const Description = styled.div`
  text-align: center;
`;
